import React, { FC } from 'react';

import classNames from 'classnames';
import { Link } from 'gatsby';
import UmbracoImage from 'components/common/Image/UmbracoImage';

import { BannerType } from './models';
import './Banner.scss';

const Banner: FC<BannerType> = ({
  bannerLink: [{ url, name }],
  bannerLinkText,
  bannerImg,
  bannerHeadline,
  className,
  bannerImgAria,
}) => {
  return (
    <div data-testid="banner" className={classNames('side-banner', className)}>
      <div className="side-banner__content">
        {/* eslint-disable-next-line */}
        <p tabIndex={0} className="side-banner__headline">
          {bannerHeadline}
        </p>
        <p className="side-banner__contact-text">
          <Link to={url} className="side-banner__link">
            {`${bannerLinkText} `}
            <span>{name}</span>
          </Link>
        </p>
      </div>
      <div className="side-banner__img-wrapper">
        <UmbracoImage image={bannerImg} alt={bannerImgAria} className="side-banner__image" />
      </div>
    </div>
  );
};

export default Banner;
