import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { Button, Card } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { FaqAccordionProps } from './model';

const FaqAccordion: FC<FaqAccordionProps> = ({ items }) => {
  return (
    <>
      {items.map(({ question, answer }, index) => {
        const [isOpened, setIsOpened] = useState<boolean>(false);

        return (
          // eslint-disable-next-line react/no-array-index-key
          <Accordion key={`faq-accordion-${index}`}>
            <Card
              className={classNames('accordion__card', { 'accordion__card--active': isOpened })}
            >
              <Accordion.Toggle
                as={Button}
                eventKey="0"
                className="accordion__header"
                onClick={() => setIsOpened(!isOpened)}
              >
                <div className="accordion__arrow-container">
                  <div className="icon-dropdown_arrow_icon accordion__arrow" />
                </div>
                <div className="accordion__question">
                  <h3 className="accordion__question-header">{question}</h3>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body className="accordion__answer">
                  <DangerouslySetInnerHtml html={answer} />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        );
      })}
    </>
  );
};

export default FaqAccordion;
